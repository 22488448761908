import { CheckoutResponseError } from '../errors/CheckoutResponseError';
import { PaymentResponseError } from '../errors/PaymentResponseError';
import { isMollieUrl } from '../validation';
export class PaymentHandler {
    static paymentProviderId = 'Mollie';
    static defaultErrorCode = 422;
    static defaultErrorMessage = 'Unknown error';
    static pendingStatus = ['init', 'pending'];
    static settledStatus = ['paid', 'done'];
    static unresolvedStatus = ['unresolved'];
    static failedStatus = ['failed'];
    static nonInteractiveTypes = ['banktransfer'];
    static mollieRedirectParameter = { name: 'step', value: 'payment' };
    static getMolliePayment(cart) {
        return cart.payments?.find((payment) => PaymentHandler.isMolliePayment(payment) &&
            !PaymentHandler.isPaymentFailed(payment) &&
            !PaymentHandler.isPaymentUnresolved(payment));
    }
    static isMolliePayment(payment) {
        return payment.paymentProvider === PaymentHandler.paymentProviderId;
    }
    static isRedirectFromMollie(query) {
        return query[PaymentHandler.mollieRedirectParameter.name] === PaymentHandler.mollieRedirectParameter.value;
    }
    static isPaymentPending(payment, strict = false) {
        const pendingStatus = !!strict
            ? PaymentHandler.pendingStatus.filter((status) => status !== 'init')
            : PaymentHandler.pendingStatus;
        return pendingStatus.includes(payment.paymentStatus);
    }
    static isPaymentSettled(payment) {
        return PaymentHandler.settledStatus.includes(payment.paymentStatus);
    }
    static isPaymentFailed(payment) {
        return PaymentHandler.failedStatus.includes(payment.paymentStatus);
    }
    static isPaymentUnresolved(payment) {
        return PaymentHandler.unresolvedStatus.includes(payment.paymentStatus);
    }
    static isMollieInteractionRequired(payment) {
        return !PaymentHandler.isNonInteractiveType(payment.paymentMethod);
    }
    static isNonInteractiveType(type) {
        return !!type && PaymentHandler.nonInteractiveTypes.includes(type);
    }
    static getCheckoutUrl(payment) {
        // Special case - non-interaction payments like bank transfers do not need a redirect to mollie
        return PaymentHandler.isMollieInteractionRequired(payment)
            ? PaymentHandler.getMollieOrderUrl(payment)
            : '/order-summary';
    }
    static getMollieOrderUrl(payment) {
        const mollieOrderUrl = payment.paymentDetails?.find((details) => details.id && details.id === 'mollieCheckoutUrl')?.value || null;
        return isMollieUrl(mollieOrderUrl) ? mollieOrderUrl : null;
    }
    static getCheckoutResponseError(error) {
        if (error instanceof CheckoutResponseError) {
            return error;
        }
        const message = error instanceof Error ? error.message : PaymentHandler.defaultErrorMessage;
        return new PaymentResponseError(message, PaymentHandler.defaultErrorCode);
    }
    static getPendingOrderAmount(cart) {
        const orderAmount = cart.sum?.centAmount || 0;
        const paidAmount = cart?.payments
            ?.filter((payment) => PaymentHandler.isMolliePayment(payment) && PaymentHandler.isPaymentSettled(payment))
            ?.reduce((amount, payment) => {
            return amount + (payment.amountPlanned?.centAmount || 0);
        }, 0) || 0;
        return Math.max(0, orderAmount - paidAmount);
    }
    static cartRequiresPayment(cart) {
        if (!cart.lineItems || cart.lineItems.length === 0) {
            return false;
        }
        return PaymentHandler.getPendingOrderAmount(cart) >= 0;
    }
}
