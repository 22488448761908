import { IncomingMessage, ServerResponse } from 'http';
import cookieCutter from 'cookie-cutter';
import ServerCookies from 'cookies';
import { SESSION_PERSISTENCE } from 'helpers/constants/auth';
import { REMEMBER_ME } from 'helpers/constants/localStorage';
import { Log } from 'helpers/errorLogger';
import { mapLanguage } from '../../project.config';

export class LocaleStorage {
  static locale = ''
}

function resolveApiHubUrl(): string {
  if (process.env['NEXT_PUBLIC_FRONTASTIC_HOST'] === undefined) {
    throw new Error(`Env variable "NEXT_PUBLIC_FRONTASTIC_HOST" not set`)
  }
  const apiHubUrl = process.env.NEXT_PUBLIC_FRONTASTIC_HOST
  /*
  if (process.env.NEXT_PUBLIC_VERCEL_ENV! === 'preview') {
    // FIXME: Get project & customer ID from configuration
    apiHubUrl =
      'https://<project>-' +
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF!.replace(/[^a-zA-Z0-9-]/g, '-') +
      '-<customer>.frontastic.dev/frontastic';
  }
  */
  return apiHubUrl
}

export function getCookieDomain(): string {
  return process.env.NEXT_PUBLIC_COOKIE_DOMAIN || ''
}

export function getSessionCookieName(): string {
  if (process.env['NEXT_PUBLIC_SESSION_COOKIE_NAME'] === undefined) {
    throw new Error(`Env variable "NEXT_PUBLIC_SESSION_COOKIE_NAME" not set`)
  }

  return process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME || ''
}

type ExpressMessages = {
  req: IncomingMessage;
  res: ServerResponse;
};

type CookieManager = {
  getCookie: (cookieIdentifier: string) => string | undefined;
  setCookie: (cookieIdentifier: string, cookieValue: string) => void;
};

export class ResponseError extends Error {
  private readonly response: Response

  constructor(response: Response) {
    super(`Got HTTP status code ${response.status} (${response.statusText})`)
    this.response = response
  }

  getResponse() {
    return this.response
  }

  getStatus() {
    return this.response.status
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchFunction = (endpointPath: string, init?: RequestInit, payload?: object) => Promise<any>;

const performFetchApiHub = async (
  endpointPath: string,
  init: RequestInit,
  payload: object = null,
  cookieManager: CookieManager,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const sessionHeaderName = 'Frontastic-Session'

  const frontasticSessionHeaders = {}

  const sessionCookieName = getSessionCookieName()

  const frontasticSessionCookie = cookieManager.getCookie(sessionCookieName)
  if (frontasticSessionCookie) {
    frontasticSessionHeaders[sessionHeaderName] = frontasticSessionCookie
  }

  const bodyOverride = payload ? { body: JSON.stringify(payload) } : {}

  const actualInit = {
    ...bodyOverride,
    ...init,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...(init.headers || {}),
      'Commercetools-Frontend-Extension-Version': process.env.NEXT_PUBLIC_EXT_BUILD_ID ?? 'dev',
      ...frontasticSessionHeaders,
      'Frontastic-Locale': mapLanguage(LocaleStorage.locale),
    },
  }

  const endpoint = resolveApiHubUrl() + endpointPath

  return await fetch(endpoint, actualInit).then((response): Response => {
    if (response.ok && response.headers.has(sessionHeaderName)) {
      cookieManager.setCookie(sessionCookieName, response.headers.get(sessionHeaderName))
    }
    return response
  })
}

export const rawFetchApiHub: FetchFunction = async (endpointPath, init = {}, payload = null) => {
  return await performFetchApiHub(endpointPath, init, payload, {
    getCookie: (cookieIdenfier) => {
      return cookieCutter.get(cookieIdenfier)
    },
    setCookie: (cookieIdenfier, cookieValue) => {
      const cookieDomain = getCookieDomain()

      const expiryDate = window.localStorage.getItem(REMEMBER_ME)
        ? new Date(Date.now() + SESSION_PERSISTENCE)
        : 'Session'

      const cookieOptions = { path: '/', expires: expiryDate }
      if (cookieDomain) {
        cookieOptions['domain'] = cookieDomain
      }

      cookieCutter.set(cookieIdenfier, cookieValue, cookieOptions)
    },
  })
}

export const handleApiHubResponse = (fetchApiHubPromise: Promise<Response | ResponseError>): Promise<object> => {
  // TODO: Handle errors
  return fetchApiHubPromise
    .then((response: Response) => {
      if (response.ok) {
        return response.json()
      }
      throw new ResponseError(response)
    })
    .catch(async (err: ResponseError) => {
      if (err && err.getResponse) {
        const response = err.getResponse()
        let error: object | string
        try {
          error = await response.json()
        } catch (e) {
          error = await response.text()
        }
        Log.error(error)
        return error
      } else {
        Log.error('Network error: ' + err)
        return 'Network error: ' + err
      }
    })
    .then((response) => {
      if (response.error) {
        throw new Error(response.errorCode)
      }

      return response
    })
}

export const fetchApiHub: FetchFunction = async (endpointPath, init = {}, payload = null) => {
  return handleApiHubResponse(rawFetchApiHub(endpointPath, init, payload))
}

export const rawFetchApiHubServerSide = async (
  endpointPath: string,
  expressMessages: ExpressMessages,
  headers: HeadersInit = [],
  init = {},
  payload = null,
  cookieManager?: CookieManager
) => {
  const cookies = new ServerCookies(expressMessages.req, expressMessages.res)

  const actualInit = {
    ...init,
    headers: {
      ...headers,
    },
  }

  return await performFetchApiHub(endpointPath, actualInit, payload, cookieManager ?? {
    getCookie: (cookieIdentifier) => {
      return cookies.get(cookieIdentifier)
    },
    setCookie: () => {
      // Do nothing. Only actions are eligible to set the session.
    },
  })
}

export const fetchApiHubServerSide = async (
  endpointPath: string,
  expressMessages: ExpressMessages,
  headers: HeadersInit = [],
  init = {},
  payload = null,
  cookieManager?: CookieManager
) => {
  return handleApiHubResponse(rawFetchApiHubServerSide(endpointPath, expressMessages, headers, init, payload, cookieManager))
}
