import { fetchApiHub } from "frontastic/lib/fetch-api-hub";
export const getVouchers = async (filters) => {
    const res = await fetchApiHub('/action/voucherify/getVouchers', { method: 'POST' }, filters);
    return res;
};
export const redeemVoucher = async (codes, accountId, orderId, SKUs) => {
    const res = await fetchApiHub('/action/voucherify/redeemVoucher', { method: 'POST' }, { codes, accountId, orderId, SKUs });
    return res;
};
export const validateVoucher = async (code, accountId) => {
    const res = await fetchApiHub('/action/voucherify/validateVoucher', { method: 'POST' }, { code, accountId });
    return res;
};
export const markCustomerAsPaid = async (accountId, orderId) => {
    const res = await fetchApiHub('/action/voucherify/markCustomerAsPaid', { method: 'POST' }, { accountId, orderId });
    return res;
};
