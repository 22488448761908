const languageMapper = {
  es: 'es_ES',
  en: 'en_GB',
  '': 'es_ES',
}

const mapLanguage = (lang) => {
  if (!languageMapper[lang]) {
    console.error(`Language mapper is missing language ${lang}`)
  }

  //If language is not defined in languageMapper then select first locale
  return languageMapper[lang] || languageMapper[Object.keys(languageMapper)[0]]
}

module.exports = { languageMapper, mapLanguage }
