const screenSizes = {
    smallMobile: 320,
    mobile: 468,
    tablet: 768,
    desktop: 1025,
    mediumDesktop: 1280,
    largeDesktop: 1440,
    hugeDesktop: 1666,
};
export const { smallMobile, mobile, tablet, desktop, mediumDesktop, largeDesktop, hugeDesktop } = screenSizes;
