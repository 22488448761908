import { CartError } from './CartError';
export class CartItemError extends CartError {
    items;
    skus;
    constructor(message, statusCode, items) {
        super(message, statusCode);
        this.name = 'cart_item_error';
        this.items = [];
        this.skus = [];
        items.forEach((item) => {
            typeof item === 'string' || typeof item === 'number' ? this.skus.push(item + '') : this.items.push(item);
        });
    }
}
