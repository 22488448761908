export class Log {
    static WARNING = 'Warning';
    static ERROR = 'Error';
    static errors = [];
    static errorLogger = null;
    static warn = (...errorData) => {
        Log.log(Log.WARNING, errorData);
    };
    static warning = (...errorData) => {
        Log.log(Log.WARNING, errorData);
    };
    static error = (...errorData) => {
        Log.log(Log.ERROR, errorData);
    };
    static log = (type, errorData) => {
        // @TODO: Do not log on production
        console.error(type + ':', ...errorData);
        // Only when in the browser, append errors to a *global* (static) variable.
        // We do this to nicely report all errors from the main rendering function
        // during development.
        //
        // Is there a way to make a react hook or context aware of this state so we
        // really update components once new errors are recorded? Remember that
        // this function is called from random functions, not necessarily only from
        // React components.
        if (typeof window !== 'undefined') {
            if (Log.errorLogger) {
                Log.errorLogger({ type: type, date: new Date(), data: errorData });
            }
            else {
                Log.errors.push({ type: type, date: new Date(), data: errorData });
            }
        }
    };
    static setErrorLogger = (errorLogger) => {
        Log.errorLogger = errorLogger;
    };
    static hasErrors = () => {
        return !!Log.errors.length;
    };
    static getErrors = () => {
        return Log.errors.sort((a, b) => a.date - b.date);
    };
}
