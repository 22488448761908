import * as yup from 'yup';
// nekom definition of a valid email address, they will not import orders with invalid email
const nekomEmailExpression = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,64}$/;
const phoneNumberExpression = /^(\+|0{1,2})[1-9]+([-\/\s]?[0-9]+)+$/;
const postalCodeExpressions = {
    at: /^[0-9]{4}$/g,
    de: /^[0-9]{5}$/g,
    _default: /^[A-Za-z0-9\- ]{1,8}$/g,
};
const urlExpression = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;
export const isCheckoutDataValid = (checkoutData, loggedIn) => {
    const schemeEmail = yup.string().email();
    const schemeReq = yup.string().required();
    return (schemeReq.isValidSync(checkoutData.email) &&
        schemeReq.isValidSync(checkoutData.selectedPaymentMethod) &&
        schemeReq.isValidSync(checkoutData.billingAddress?.phone) &&
        schemeEmail.isValidSync(checkoutData.email) &&
        (checkoutData.billingSameAsShipping || isAddressValid(checkoutData.shippingAddress, loggedIn)) &&
        isAddressValid(checkoutData.billingAddress, loggedIn));
};
export const isAddressValid = (address, loggedIn) => {
    const addressShape = loggedIn
        ? { addressId: yup.string().required() }
        : {
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            streetName: yup.string().required(),
            streetNumber: yup.string().required(),
            postalCode: yup.string().required(),
            city: yup.string().required(),
            country: yup.string().required(),
        };
    const scheme = yup.object().shape(addressShape);
    return scheme.isValidSync(address);
};
export const isMollieUrl = (url) => {
    return yup.string().matches(urlExpression).isValidSync(url);
};
export const isItemsListValid = (cartData) => {
    //some products are out of stock?
    return !cartData?.lineItems?.find((item) => !item.variant.isOnStock);
};
export const isValidPostalCode = (postalCode, country, throwError) => {
    const pattern = postalCodeExpressions[country.toLowerCase()] || undefined;
    if (pattern) {
        return yup.string().matches(pattern).isValidSync(postalCode.toString());
    }
    if (throwError) {
        throw new Error(`Unsupported country ${country}`);
    }
    return yup.string().matches(postalCodeExpressions['_default']).isValidSync(postalCode.toString());
};
export const isValidPhoneNumber = (phoneNumber) => {
    return yup.string().matches(phoneNumberExpression).isValidSync(phoneNumber.toString());
};
export const isValidEmail = (emailAddress) => {
    return (yup.string().email().isValidSync(emailAddress) &&
        yup.string().matches(nekomEmailExpression).isValidSync(emailAddress));
};
export const isValidRegistration = (data) => {
    return (yup.string().required().isValidSync(data.email) &&
        yup.string().required().isValidSync(data.password) &&
        yup.string().required().isValidSync(data.confirmPassword) &&
        yup.string().oneOf([data.password]).isValidSync(data.confirmPassword) &&
        isValidEmail(data.email));
};
