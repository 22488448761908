import React from 'react'
import type { AppProps } from 'next/app'
import Toaster from 'components/commercetools-ui/toaster'
import { appWithTranslation } from 'next-i18next'
import { FrontasticProvider } from 'frontastic'
import '../styles/themes/default.css'
import 'tailwindcss/tailwind.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/app.scss'
import { UserProvider } from '@auth0/nextjs-auth0/client'
// eslint-disable-next-line
import 'swiper/css'

function FrontasticStarter({ Component, pageProps }: AppProps) {
  return (
    <FrontasticProvider>
      <UserProvider>
        <Component {...pageProps} />
        <Toaster />
      </UserProvider>
    </FrontasticProvider>
  )
}

export default appWithTranslation(FrontasticStarter)
